<template>
  <div id="hot-pairs" class="hot-pairs">
    <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 class="h4 mb-0 font-size-125 font-family-krona">Hot Pairs</h2>
          </div>
          <div>
            <a href="">More<span class="ml-2"><font-awesome-icon :icon="['fas', 'chevron-right']"/></span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body p-0">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item w-50">
            <a class="nav-link active" :id="'bsc-tab-'+type" data-toggle="tab" :href="'#bsc-'+type" role="tab" :aria-controls="'bsc-'+type" aria-selected="true"><img height="24" src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png" class="mr-2">BSC</a>
          </li>
          <li class="nav-item w-50">
            <a class="nav-link" :id="'ethereum-tab-'+type" data-toggle="tab" :href="'#ethereum-'+type" role="tab" :aria-controls="'ethereum-'+type" aria-selected="false"><img height="24" src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" class="mr-2"/>Ethereum</a>
          </li>
        </ul>

        <div class="tab-content" :id="'tab-content-'+type">
          <div class="tab-pane fade show active" :id="'bsc-'+type" role="tabpanel" :aria-labelledby="'bsc-tab-'+type">
            <table class="table mb-0">
              <tbody>
                <template v-for="(token, index) in tokenListBSC" v-bind:key="index">
                  <tr class="tr-clickable" v-if="index < 5" @click="goToDetail(token)" >
                    <td class="white-space-nowrap px-2" :class="[{'border-0': index == 0}]">
                      <span class="mr-2">#{{ index + 1 }}</span>

                      <span class="altLogo mr-2">{{ token.attributes.name[0] }}</span>
                      <span>{{ token.attributes.name.trim() }}</span>/<span class="text-muted font-size-075">{{ token.sideSymbol.trim() }}</span>
                    </td>
                    <td class="font-size-080 px-2" :class="[{'border-0': index == 0}]">$<span v-html="$fixPrice(token.attributes.base_token_price_usd,4)"></span></td>
                    <td :class="[{'border-0': index == 0}]" class="text-right px-2"><span class="h6 mb-0 ml-2" :class="[{'text-danger':token.attributes.price_change_percentage.h24 < 0},{'text-success':token.attributes.price_change_percentage.h24 >= 0}]">{{$fixPrice(token.attributes.price_change_percentage.h24, 2)}}%</span></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="tab-pane fade" :id="'ethereum-'+type" role="tabpanel" :aria-labelledby="'ethereum-tab-'+type">
            <table class="table mb-0">
              <tbody>
                <template v-for="(token, index) in tokenListEthereum" v-bind:key="index">
                  <tr class="tr-clickable" v-if="index < 5" @click="goToDetail(token)" >
                    <td class="white-space-nowrap px-2" :class="[{'border-0': index == 0}]">
                      <span class="mr-2">#{{ index + 1 }}</span>

                      <span class="altLogo mr-2">{{ token.attributes.name[0] }}</span>
                      <span>{{ token.attributes.name }}</span>/<span class="text-muted font-size-075">{{ token.sideSymbol }}</span>
                    </td>
                    <td class="font-size-080 px-2" :class="[{'border-0': index == 0}]">$<span v-html="$fixPrice(token.attributes.base_token_price_usd,4)"></span></td>
                    <td :class="[{'border-0': index == 0}]" class="text-right px-2"><span class="h6 mb-0 ml-2" :class="[{'text-danger':token.attributes.price_change_percentage.h24 < 0},{'text-success':token.attributes.price_change_percentage.h24 >= 0}]">{{$fixPrice(token.attributes.price_change_percentage.h24, 2)}}%</span></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      tokenListBSC: '',
      tokenListEthereum: '',
      type: 'hot-pairs'
    };
  },
  created() {
    axios.get(`/v2/tokens/hotpairs?chain=bsc`)
      .then(async (response)=> {
        this.tokenListBSC = response.data.body.data;

        this.tokenListBSC.forEach((elem,index) => {
          let name = this.tokenListBSC[index].attributes.name.split('/');
          this.tokenListBSC[index].attributes.name = name[0];
          this.tokenListBSC[index].sideSymbol = name[1];
        })  

      });
    axios.get(`/v2/tokens/hotpairs?chain=eth`)
      .then(async (response)=> {
        this.tokenListEthereum = response.data.body.data;
        this.tokenListEthereum.forEach((elem,index) => {
          let name = this.tokenListEthereum[index].attributes.name.split('/');
          this.tokenListEthereum[index].attributes.name = name[0];
          this.tokenListEthereum[index].sideSymbol = name[1];
        })
        this.$emit('widgetLoaded');
      });  

  },
  methods: {
    goToDetail(crypto){
      this.$router.push({ name: 'crypto-detail', params: { address: crypto.mainToken.address, chain: 'bsc' }, query: { symbol: crypto.mainToken.symbol}});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .altLogo{
    background: #ddd;
    height:24px;
    width:24px;
    display:inline-flex;
    align-items:center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 500;
  }

</style>
